import dayjs from 'dayjs';
import { api } from 'new/api/axios';
import {
  downloadFileFromResponse,
  getRequestConfigForFileDownload,
} from 'new/api/responseHandlers';
import { Id } from 'new/api/types';
import { createEndpoint } from 'new/api/utils';
import { MultiReportParams } from 'new/modules/MultiLocationReports/types';
import {
  CSV_ACCEPT_HEADER,
  PDF_ACCEPT_HEADER,
  XLSX_ACCEPT_HEADER,
} from 'new/modules/Reports/constants';
import {
  DownloadPayoutReportParams,
  FileTypes,
  PayoutReportsResponse,
} from 'new/modules/Reports/types';

export const getPayoutReports = createEndpoint<PayoutReportsResponse, unknown>({
  url: '/DBreports/dentist/payouts/:id/list',
  endpoint: ({ url }) => api.get(url),
});

export const filetypeHeaderMap: Record<
  DownloadPayoutReportParams['fileType'],
  string
> = {
  csv: CSV_ACCEPT_HEADER,
  xlsx: XLSX_ACCEPT_HEADER,
  pdf: PDF_ACCEPT_HEADER,
};

const filetypeUrlMap = {
  csv: 'csv=true&download=true',
  xlsx: 'xlsx=true&download=true',
  pdf: 'download=true',
};

export const downloadPayoutReport = ({
  dentistInfoId,
  id,
  fileName,
  fileType,
  upgraded,
}: DownloadPayoutReportParams) => {
  const url = `/DBreports/dentist/${dentistInfoId}/${
    upgraded ? 'upgrade_payout' : 'payout'
  }/${id}?${filetypeUrlMap[fileType]}`;

  return api
    .get<never>(url, getRequestConfigForFileDownload(fileType))
    .then((response) => {
      downloadFileFromResponse(response.data, fileType, fileName);

      return response;
    });
};

export const downloadMultiLocationReport = ({
  fileType,
  reportType,
  ...bodyParams
}: MultiReportParams) => {
  const url = `/multi-reports/${reportType}?${filetypeUrlMap[fileType]}`;

  return api
    .post<never>(
      url,
      { ...bodyParams, fileType },
      getRequestConfigForFileDownload(fileType),
    )
    .then((response) => {
      downloadFileFromResponse(response.data, fileType, reportType);

      return response;
    });
};

export const downloadMasterMultiLocationReport = ({
  fileType,
  reportType,
  ...bodyParams
}: MultiReportParams) => {
  const url = `/DBreports/admin/master_report_monthly/${dayjs(
    bodyParams.startDate,
  ).format('YYYY')}/${dayjs(bodyParams.startDate).format('MMM')}/by_dentist/${
    bodyParams?.userId
  }?csv=true&download=true`;

  return api
    .post<never>(
      url,
      { ...bodyParams, fileType },
      getRequestConfigForFileDownload(fileType),
    )
    .then((response) => {
      downloadFileFromResponse(response.data, fileType, reportType);

      return response;
    });
};

export const downloadAdminOfficeActiveMembersReport = ({
  dentistInfoId,
}: {
  dentistInfoId: Id;
}) => {
  const url = `/admin/reports/office/${dentistInfoId}/activeMembers`;

  const fileType = 'xlsx';
  const fileName = 'ActiveMembersReport';
  return api
    .get(url, getRequestConfigForFileDownload(fileType))
    .then((response) => {
      downloadFileFromResponse(response.data as Blob, fileType, fileName);
    });
};

export const downloadQR = ({
  dentistInfoId,
  enrollmentDocumentId,
  type,
}: {
  dentistInfoId: Id;
  enrollmentDocumentId: number;
  type: FileTypes;
}) => {
  const url = `office/${dentistInfoId}/marketing/enrollment-qr-document/${type}/${enrollmentDocumentId}`;

  const fileType = type;
  const fileName = 'QR';

  return api
    .get(url, getRequestConfigForFileDownload(fileType))
    .then((response) => {
      downloadFileFromResponse(response.data as Blob, fileType, fileName);
    });
};
